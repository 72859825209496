import React from "react";
import logo from '../assets/logo_green.png';
import './Navigationbar.css';
import { Link } from "react-scroll";
import hamburger from '../assets/hamburger.png';
import {motion} from 'framer-motion'
import { useState } from "react";

const variants = {
  open: { opacity: 1, x: 0, y: "4vh", transition: { type: "spring", stiffness: 300, damping: 24 }},
  closed: { opacity: 0, x: "-10%", transition: { duration: 0.2 } },
}

const Navigationbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const openMenu = () => {
    isOpen ? document.getElementById("hamburger-overlay").style.display = "none" : document.getElementById("hamburger-overlay").style.display = "flex";
    isOpen ? console.log("closing"): console.log("opening");
    setIsOpen(!isOpen);
  }
  const closeMenu = () => {
      const hamburgerOverlay = document.getElementById("hamburger-overlay");
      hamburgerOverlay.style.display = "none";
      setIsOpen(!isOpen);
  };

  return (
    <div id="nav-bar"> 
        <div id="hamburger-overlay">
          <motion.nav
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          >
            <div id="hamburger-overlay-content">
            <Link to="home" spy={true} smooth={true}  duration={500} activeClass="active-link" ><p onClick={closeMenu}>Home </p></Link>
            <Link to="collection" spy={true} smooth={true}  duration={500} activeClass="active-link"><p onClick={closeMenu}>Our Collections </p></Link> 
            <Link to="contact" spy={true} smooth={true} duration={500} activeClass="active-link"><p onClick={closeMenu}>About Us </p></Link>    
            </div>
          </motion.nav>
        </div>
      <div id="logo_div">
        <img className="logo_img" id = "logo_img" src={logo} alt="Logo"></img>
      </div>
      <div className="nav-buttons" id="nav-button">
        <Link to="home" spy={true} smooth={true}  duration={500} activeClass="active-link">
          <div className="nav-button" id="home-button">Home</div>
        </Link>
        <Link to="collection" spy={true} smooth={true}  duration={500} activeClass="active-link">
          <div className="nav-button" id="collection-button">Collections</div>
        </Link>
        <Link to="contact" spy={true} smooth={true} duration={500} activeClass="active-link">
          <div className="nav-button" id="about-button">About Us</div>
        </Link>
      </div>
      <div id = 'hamburger'>
        <img id = 'img-hamburger' src={hamburger} alt="hamburger" onClick={openMenu} ></img>
      </div>
    </div>
  );
}

export default Navigationbar;
