
import React from 'react';
import './App.css';
import './utils/font.css';
import Home from './pages/home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PayalPage from './collection/payal-page';
import './utils/media.css';

function App() {
  

  return (
    <div className="App">  
      <BrowserRouter>
      <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/payal" element={<PayalPage />}/>
      </Routes>
    </BrowserRouter> 
    </div>
  );
}

export default App;
