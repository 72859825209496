import React from 'react';
import '../components/Payal.css';
import ParentComponent from '../components/ParentImageGallery';
import {Link} from 'react-router-dom';
import back_button from '../assets/back_button.svg'
const PayalPage = () => {
    console.log("PayalPage");

    return(
        <div className = 'display' id='collection-display'>
            <Link to='/'><button id='back-button'>
                 <img src={back_button} alt='back-button' id='back-button'/>
            </button></Link>
            <div className = 'page-heading'> Payal Collection </div>
            <ParentComponent/>
        </div>
    );
}

export default PayalPage;