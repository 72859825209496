import React from 'react';
import logo from '../assets/logo.png';
import './Contact.css';
import {motion} from 'framer-motion'

const Contact = () => {
    return(
            <div id = 'contact'>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    // animate={{ x: 100 }}
                    transition={{ delay:0.15, duration: 0.2, ease: 'easeIn' }}
                    variants={{
                    visible: { opacity: 1, scale:1, translateY: '2px', translateX: 0},
                    hidden: { opacity: 0, scale: 0.8, translateY: '2px'}
                    }}
                    className ='contact-bar'>
                    <div id = "contact-text"> 
                        Contact
                    </div>
                    <div id = "contact-location" className='contact-field'> 
                        <p className = 'contact-heading'>Location</p>
                        <p className='contact-detail'>136-B,&nbsp; Chaubeji ka Phatak,&nbsp; Kinari Bazaar,&nbsp; Agra,&nbsp; India</p>
                    </div>
                    <div id = "contact-email" className='contact-field'> 
                        <p className = 'contact-heading'>Email Address</p> 
                        <p className='contact-detail'>rajambah@gmail.com</p>
                    </div>
                    <div id = "contact-phone" className='contact-field'> 
                        <p className = 'contact-heading'>Phone Number</p>
                        <p className='contact-detail'>+91 96349 72600</p>
                    </div>
                </motion.div>
                <div id = 'contact_logo' >
                        <img className = 'contact_logo_img' src={logo} alt='logo' />
                </div>
            </div>
    );
}

export default Contact;