import React from "react";
import {motion} from 'framer-motion'

const FadeInWhenVisible = ({ children, translateY, translateX }) => {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        // animate={{ x: 100 }}
        transition={{ delay:0.15, duration: 0.2, ease: 'easeIn' }}
        variants={{
          visible: { opacity: 1, scale:1, translateY: translateY, translateX: translateX},
          hidden: { opacity: 0, scale: 0.8, translateY: translateY}
        }}
      >
        {children}
      </motion.div>
    );
}

export default FadeInWhenVisible;