import React from 'react'
import ReactTypingEffect from 'react-typing-effect';
import './Homepage.css';
import { forwardRef } from 'react';


const Homepage= forwardRef(({homeRef}) => {
    return(       
        <div className='home_div' ref={homeRef} >
            <div className='bg_mask'></div>
            <div className='text_div' id='text_div'>
                <h1 className='text1' id='text1'> 
                <ReactTypingEffect
                    text = {["premium", "luxury"]}
                    speed = {150}
                    eraseSpeed = {150}
                    eraseDelay = {1000}
                    typingDelay = {500}
                    cursor = {"."}
                /></h1>
                <h1 className='text2' id='text2'>  collection</h1>
                <div  className='company-label' id='company-label'>
                    RJDX PAYAL
                </div>
            </div>
        </div>
        

    )
});

export default Homepage;