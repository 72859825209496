import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {motion} from 'framer-motion'
import Lightbox from './lightbox';

const ParentComponent = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedImage, setSelectedImage] = useState('');
  const [showLightbox, setShowLightbox] = useState(false);

  useEffect(() => {
    console.log("ParentComponent_newImprovement");
    const fetchImageUrls = async() => {
      try {
        console.log("going to Fetch urls");
        const response = await axios.get(`https://detonar.pythonanywhere.com/payal?page=${page}&per_page=12`);
        console.log("Urls fetched");
        console.log("Response: ", response.data);
        setImageUrls(prevImageUrls => [...prevImageUrls, ...response.data]);
        if (response.data.length === 0) {
          setHasMore(false);
        }
        setPage(prevPage => prevPage + 1);
      } catch (error) {
        console.error('Error fetching image URLs:', error);
      }
    };
    if (hasMore) {
      fetchImageUrls();
    }
    
  }, [page, hasMore]);

  const imageClickHandler = (url, index) => {
    let baseUrl  = 'https://ik.imagekit.io/rjdx/Payal%20Photos-originalsize/'
    let imageName = url.slice(48);
    url = baseUrl.concat(imageName);
    setSelectedImage(url);
    console.log("Image clicked ",index, url);
    setShowLightbox(true);
  };
  const closeLightbox = () => {
    setShowLightbox(false);
  };
 
    return (

      <div id='collections-display' className='catalogs'>
           
        {imageUrls.map((url, index) => (
        <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        // animate={{ x: 100 }}
        transition={{ delay:0.15, duration: 0.2, ease: 'easeIn' }}
        variants={{
          visible: { opacity: 1, scale:1, translateY: 0, translateX: '0'},
          hidden: { opacity: 0, scale: 0.8, translateY: 0}
        }}
        key = {index} className='image-box'>
            <img src={url} key={index} className='catalog-image' alt={`index` } onClick={() => imageClickHandler(url, index)} />
        </motion.div>
      ))}
      {selectedImage && showLightbox && 
        
        <Lightbox imageUrl={selectedImage} onClose={closeLightbox} />
      }
     
      </div>
      );
    
};

export default ParentComponent;