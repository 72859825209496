import React from "react";
// import { FullPage, Slide } from "react-full-page";
import Homepage from "../components/Homepage";
import Collections_section from "../components/Collections_section";
import Payal from "../components/Payal";
import Contact from "../components/Contact";
import Navigationbar from "../components/Navigationbar";

const Home = () => {
  return (
    <div>
      <Navigationbar />
          <div name="home">
            <Homepage />
          </div>
          <div name="collection">
            <Collections_section />
          </div>
          <div name="contact">
            <Contact />
          </div>
    </div>
  );
};

export default Home;
