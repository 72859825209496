import React from 'react'
import {Link} from 'react-router-dom';
import './Collections_section.css';
const Collections_section= () => {
    return(
        <div>
            <div className='collections_div'>
            <div className='bg_mask' id='collection-mask'></div>
                <div id='text2_div'> Our<br></br> Specialties </div>
                <div className='message_div'>
                    <div className='text-box'> 
                    <p id = 'text-box'> Explore the Elegant Agra Fancy Collection </p>
                    </div>
                    <div  className='button_div' id = 'view-button-div'>
                        <Link to='/payal'><button id='view-button' className='button' >view more</button></Link>
                    </div>
                </div>

            </div>
            
        </div>
    )
};

export default Collections_section;