import React from 'react';
import './lightbox.css';
import FadeInWhenVisible from '../animations/fadeInWhenVisible';
const Lightbox = ({ imageUrl, onClose }) => {
  return (
    <div className="lightbox-overlay" onClick={onClose}>
    <FadeInWhenVisible translateX='0vh'>
      <div className="lightbox-content">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <img src={imageUrl} alt="Selected" className="lightbox-image" />
      </div>
      </FadeInWhenVisible>
    </div>
  );
};

export default Lightbox;
